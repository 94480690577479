export default [
  { name: '矚目之地 ', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',mobileOffset: -40, offset: 0 },
  { name: '交通機能 ', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',mobileOffset: -40, offset: 0 },
  { name: '公園首席 ', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',mobileOffset: -50, offset: 0 },
  { name: '凌空美學 ', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',mobileOffset: -45, offset: -40 },
  { name: '雙制震築 ', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',mobileOffset:-44, offset: 0 },
  { name: '五星公設 ', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '',mobileOffset:-44, offset: 0 },
  { name: '空間美學 ', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '',mobileOffset:-44, offset: 25 },
  { name: '預約賞屋 ', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',mobileOffset:-44, offset: 0 },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, offset: 0 },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, offset: -150 },
]
