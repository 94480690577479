<template>
  <div class="sideNav">
    <div :class="`nav ${isOpen ? 'open' : ''}`">
      <div class="menu" @click="toggleSidebar">
        <span class="menu_icon"></span>
        <span class="menu_text"></span>
        <img src="../projects/hy1/s1/menulogo.png" alt="" class="logo">
        <div class="bg">
          <img src="../projects/hy1/s1/menubg.png" alt="" class="building">
        </div>
      </div>
     <!-- <img src="../assets/img/close.png" alt="" class="close" v-if="isOpen" @click="isOpen = false">  -->
      <div :class="`mask ${isOpen ? 'open' : ''}`" @click="toggleSidebar" />
      <ul :class="`navlist ${isOpen ? 'open': ''}`">
        <li :key="item.name" v-scroll-to="{ element: `#${item.section}`, offset: isMobile ? item.mobileOffset:item.offset }" v-for="item in list" class="flex-ac" @click="toggleSidebar">
          <span class="link">
            <img v-if="item.imgSrc" :src="item.imgSrc" alt />
            <span>
              <h3 class="title">{{item.name}}</h3>
              <span class="subTitle">{{item.subTitle}}</span>
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { isMobile, isTablet } from '@/utils'
import navList from '@/info/navList'

export default {
  name: 'sideNavigation',
  components: {},
  data() {
    return {
      isOpen: false,
      isMobile,
      isTablet,
      list: navList,
    }
  },

  computed: {
    offset() {
      return 0
    },
  },

  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/style/variableColor.scss';
@import '../assets/style/variableDefault.scss';
@import '../assets/style/function.scss';

.navigation {
  height: $nav_phone_height;
  z-index: 110;
}

.nav-container {
  display: block;
  height: auto;
}

.logo {
  width: $logo_phone_width;
  left: 15px;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;z-index: 100;
  height:$nav_pc_height;
}

.menu {
  text-align: center;
  position: relative;
// position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .bg {
  position: relative;
    width: 100vw;
    top: 0;
    left: 0;
    width: 100vw;
    z-index:111;
    height: size(968);
    background: radial-gradient(
      ellipse at 50%,
      rgb(164, 31, 50) 9%,
      rgb(13, 22, 21) 100%
    );
    transform: skewY(-3.5deg) translateY(-88%);
    transform-origin: 0 0;
    overflow: hidden;
    transition: all 0.8s;
  }

  .building {
    @include img_r_pc(531, 282, 388);
    transform-origin: 0 0;
    transform: skewY(5deg) translateY(83%);
    opacity: 0;
    transition: all 1s;
    transition-delay: 0.5s;
  }

  .logo {
    position: absolute;
    width: size(102);
    top: size(20);
    left: size(111);
    z-index: 113;
    transition: all 0.3s;
  }

  img {
    width: 100%;
  }
}
/*
.close {
  position: fixed;
  width: size(36);
  top: size(31);
  left: size(34);
  z-index: 310;
  cursor: pointer;
  transition: all 0.3s;
} */
.menu_icon,
.menu_icon::after,
.menu_icon::before {
  position: absolute;
  display: block;
  width: 100%;
  height: size(3);
  left: 0;
  background-color: #fff;
  content: '';
  transition: all 0.3s;
  top: 0;
  z-index: 120;
}
.menu_icon {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-indent: -9999px;
  line-height: 0;
  top: size(45);
  left:size(40);
  width:size(40);
  margin-top: -2px;
}

.menu_icon::before {
  transform: translateY(400%);
}

.menu_icon::after {
  transform: translateY(-400%);
}
.menu_text {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: size(100);
  z-index: 200;
}

.open {
  .menu_icon {background-color: #fff0;
 //   width: 0;
  }

  .logo {
    width: size(204);
  }

  .menu_icon:before {
  transform: translateY(0%) rotate(45deg);
  // opacity: 0;
  }

  .menu_icon:after {
  transform: translateY(0%) rotate(-45deg);
 //  opacity: 0;
  }
  .menu {
    .bg {
      transform: skewY(-5deg);
    }

    .building {
      transform-origin: 0 0;
      transform: skewY(5deg) translateY(0%);
      transition: all 1s;
      opacity: 1;
    }
  }
}
.navlist {
  position: fixed;
  z-index: 111;
  width: 0;
  overflow: hidden;
  left: 25px;
  top: $nav_phone_height;
  height: calc(100vh - #{$nav_phone_height});
  text-align: center;
  transition: all 1s ease-in;
  display: flex;
  flex-wrap: wrap;
  transform: translateX(0%);
  align-content: center;

  li {
    height: 60px;
    margin-bottom: 5px;
    width: 100%;
  }

  .link {
    height: 60px;
    width: 100%;
    font-size: 17px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
    cursor: pointer;
    padding: 0 20px 0 0;

    img,
    span {
      display: block;
    }
    span {
      line-height: 16px;
      white-space: nowrap;
    }

    &::before {
      position: absolute;
      display: block;
      right: 185px;
      content: '';
      top: calc(50% - 0.5em);
      width: 45px;
      height: 1px;
      opacity: 1;
      transition: all 0.3s;
      border: 1px solid #fff;
      border-width: 1px 1px 0 0;
    }
    &:hover:before {
      background: none;
      width: 15px;
      height: 15px;
      margin-top: -5px;
      border-color: #c00;
      transform: rotate(45deg);
      animation: menu_l 0.3s infinite alternate ease-out;
    }

    .subTitle {
      font-family: 'Barlow Condensed', sans-serif;
      margin-top: 5px;
      color: $nav_link_subtitle_color;
    }
    .title {
      font-size: size(30);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: size(0.3);
      text-align: left;
      color: $nav_link_color;
      margin-top: size(-10);
      color: #fff;
    }
  }
  @keyframes menu_l {
    to {
      right: 200px;
    }
  }

  &::before {
    position: absolute;
    display: block;
    left: 0;
    background-color: #fff;
    content: '';
    top: 0;
    width: 1px;
    height: 0px;
    opacity: 0;
    transition: all 1s;
  }
  &.open::before {
    height: calc(50vh + 223px);
    opacity: 1;
  }
  &.open {
    width: 230px;
    transform: translateX(0%);
    opacity: 1;
 //   z-index: 200;

    .link {
      .divided {
        display: none;
      }

      &:hover {
        .title,
        .subTitle {
          color: $nav_link_hover_color;
        }
      }
    }
  }
}

.mask {
  width: 100vw;

  top: $nav_phone_height;
  right: 0;
  background: transparent;
  position: absolute;
  z-index: -1;
  height:100%;
  opacity: 0;
  transition: all 0.3s ease-in;
  background: rgba(0, 0, 0, 0.6);
  // background: -moz-linear-gradient(
  //   left,
  //   rgba(255, 255, 255, 0.8) 0%,
  //   rgba(255, 255, 255, 0.8) 50%,
  //   rgba(255, 255, 255, 0) 100%
  // );
  // background: -webkit-linear-gradient(
  //   left,
  //   rgba(255, 255, 255, 0.8) 0%,
  //   rgba(255, 255, 255, 0.8) 50%,
  //   rgba(255, 255, 255, 0) 100%
  // );
  // background: linear-gradient(
  //   to right,
  //   rgba(255, 255, 255, 0.8) 0%,
  //   rgba(255, 255, 255, 0.8) 50%,
  //   rgba(255, 255, 255, 0) 100%
  // );
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#00ffffff',GradientType=1 );

  &.open {
    display: block;
    width: 100vw;
    opacity: 1;
    z-index: 110;
  }
}

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* .navigation {
    height: $nav_tablet_height;
    justify-content: center;
  }

  .logo {
    width: $logo_tablet_width;
    // left: -240px;
  }

  .navlist {
    width: 0%;
  }

  .navlist .link {
    font-size: 20px;
    width: 230px;
    white-space: nowrap;

    img {
      // height: 35px;
      margin-bottom: 5px;
    }

    .divided {
      margin-left: 6px;
    }
  }
  */
}

/* 螢幕尺寸標準 */
/* 手機尺寸 */
@media only screen and (max-width: 767px) {
.nav {
  height:0;
  &.open{
  height:100%;}
}
  .menu {
    width: sizem(60);
    padding-top: sizem(10);
    .bg {
      height: sizem(558);
      transform: skewY(-5deg) translateY(-88%);
      transform-origin: 0 0;
      overflow: hidden;
      transition: all 0.8s;
    }

    .building {
      display: none;
      // @include img_r_m(531, 282, 388);
      // transform-origin: 0 0;
      // transform: skewY(5deg) translateY(83%);
      // opacity: 0;
      // transition: all 1s;
      // transition-delay: 0.5s;
    }

    .logo {
      position: absolute;
      width: sizem(56.7);
      top: sizem(13);
      left: sizem(61);
      transition: all 0.3s;
    }
  }

  .menu_icon,
  .menu_icon::after,
  .menu_icon::before {
    position: absolute;
    display: block;
    width: 100%;
    height: sizem(3);
    left: 0;
    background-color: #fff;
  }
  .menu_icon {
  top: sizem(30);
  left:sizem(20);
  width:sizem(30);
    margin-top: -2px;
  }

  .menu_icon::before {
    -webkit-transform: translateY(300%);
    transform: translateY(300%);
  }

  .menu_icon::after {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }
.menu_text {
  height: sizem(100);
}

  .open {
    .menu_icon {
  left:sizem(30);
     // width: 0;
    }

    .logo {
      width: sizem(157);
      top: sizem(25);
      left: sizem(108);
    }

    .menu {
      .bg {
        transform: skewY(-5deg);
      }

      .building {
        transform-origin: 0 0;
        transform: skewY(5deg) translateY(0%);
        transition: all 1s;
        opacity: 1;
      }
    }
  }

  .navlist {
    height:  sizem(556);
    padding:   sizem(130) 0 0 0;
    li {
      height: sizem(43);
    }
    .link {
      height: 60px;
      width: 100%;
      font-size: 17px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      position: relative;
      cursor: pointer;
      padding: 0 20px 0 0;

      img,
      span {
        display: block;
      }
      span {
        line-height: 16px;
        white-space: nowrap;
      }

      &::before {
        position: absolute;
        display: block;
        right: 185px;
        content: '';
        top: calc(50% - 0.5em);
        width: 45px;
        height: 1px;
        opacity: 1;
        transition: all 0.3s;
        border: 1px solid #fff;
        border-width: 1px 1px 0 0;
      }
      &:hover:before {
        background: none;
        width: 15px;
        height: 15px;
        margin-top: -5px;
        border-color: #c00;
        transform: rotate(45deg);
        animation: menu_l 0.3s infinite alternate ease-out;
      }

      .subTitle {
        font-family: 'Barlow Condensed', sans-serif;
        margin-top: 5px;
        color: $nav_link_subtitle_color;
      }
      .title {
        font-size: sizem(22);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: size(0.22);
        text-align: left;
        color: $nav_link_color;
        margin-top: sizem(-10);
        color: #fff;
      }
    }
  }

}
</style>
