<template>
  <div class="section5">
    <div class="wide-img">
      <div v-if="!isMobile" v-rellax="{
          // Rellax Options
          // See: https://github.com/dixonandmoe/rellax#features
          speed:-9
        }">
        <img src="./s5/bg.jpg" alt="img">
      </div>
      <div v-if="isMobile" v-rellax="{
          // Rellax Options
          // See: https://github.com/dixonandmoe/rellax#features
          speed:-2
        }">
        <img src="./s5/bg.jpg" alt="img">
      </div>
    </div>
    <div class="border-frame" data-aos="fade-left" data-aos-delay="400"></div>
    <div class="swipe absolute" @mouseenter.stop="toggleTimer = false" @mouseleave.stop="toggleTimer = true" data-aos="fade-left" data-aos-delay="200">
      <div class="swipe-wrap relative" v-touch:swipe.left="decIndex" v-touch:swipe.right="addIndex">
        <transition-group name="swipe-fade" mode="out-in">
          <div v-for="(slide, i) in slideList" v-show="slideIndex === i" :key="slide.img  + i" :class="`swipe-item absolute`">
            <img :src="slide.img" alt="">
            <!-- <div class="slide-name absolute" v-html="slide.name"></div> -->
          </div>
        </transition-group>
        <div class="pagination absolute flex-ac" v-if="isPC">
          <div :class="`pagination-dot`" v-for="(slide, index) in slideList" :key="slide.img + '-dot' + index" @click="goTo(index)"><span :class="`${slideIndex === index ? 'active' : ''}`"></span></div>
        </div>
        <div class="swipe-btns absolute flex-ac flex-jb" v-if="isMobile">
          <img src="./all/prev-btn.png" alt="" class="prev-btn" @click="decIndex">
          <img src="./all/next-btn.png" alt="" class="next-btn" @click="addIndex">
        </div>
      </div>
    </div>
    <img src="./s3/icon.png" :alt="`${info.caseName}_img`" class="icon" data-aos="fade-up" data-aos-delay="200">
    <h1 class="label" data-aos="fade-up" data-aos-delay="200">
      PARK
    </h1>
    <h1 class="s-title" data-aos="fade-up" data-aos-delay="400">
      台灣各大城市<br v-if="isMobile"> 都在追求一席<br v-if="isMobile">公園豪邸
    </h1>
    <h1 class="title" data-aos="fade-up" data-aos-delay="500">
      綠意首席<br v-if="isMobile"> 眾城皆求
    </h1>
    <h3 class="s-desc" data-aos="fade-up" data-aos-delay="600" v-if="isPC">
      大安森林公園：台北市心綠肺，週邊第一排豪邸極度高貴。<br />
      新北四號公園：雙和皆知其尊貴地位，首席名邸歷久不衰。<br />
      台中市綠園道：週邊第一席稀少不凡，層峰爭搶綿延綠意。<br />
      高雄美術館特區：綠意成就人文，高雄港督豪邸聚集之地。
    </h3>
    <h3 class="s-desc" data-aos="fade-up" data-aos-delay="600" v-if="isMobile">
      大安森林公園<br />
      台北市心綠肺，週邊第一排豪邸極度高貴。<br /><br />
      新北四號公園<br />
      雙和皆知其尊貴地位，首席名邸歷久不衰。<br /><br />
      台中市綠園道<br />
      週邊第一席稀少不凡，層峰爭搶綿延綠意。<br /><br />
      高雄美術館特區<br />綠意成就人文，高雄港督豪邸聚集之地。<br />
    </h3>
    <h3 class="desc" data-aos="fade-up" data-aos-delay="700" v-if="isPC">
      公園宅的價值，各大城市皆尊貴珍稀<br />
      「鴻苑」位於桃園最大萬坪「風禾公園」第一排，以極佳的角度坐擁綠景。
    </h3>
    <h3 class="desc" data-aos="fade-up" data-aos-delay="700" v-if="isMobile">
      公園宅的價值，各大城市皆尊貴珍稀<br /><br />
      「鴻苑」位於桃園最大萬坪「風禾公園」第一排，以極佳的角度坐擁綠景。
    </h3>
    <h3 class="subtitle" data-aos="fade-up" data-aos-delay="800">
      以綠意構築價值 以首席堆疊尊榮
    </h3>
    <img src="./s3/img2.png" :alt="`${info.caseName}_img`" class="arrow2" data-aos="fade-left" data-aos-delay="800">
    <img src="./s3/img1.png" :alt="`${info.caseName}_img`" class="arrow1" data-aos="fade-left" data-aos-delay="600">

  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section5 {
  width: 100%;
  height: size(680 + 778);
  min-height: size(680 + 778);
  max-height: size(680 + 778);
  position: relative;
  // background-color: #fff;
  // min-height: size(900);
  // background-image: url('./s2/bg.jpg');
  // background-size: 100% 100%;
  // background-position: 0 0;
  // background-attachment: fixed;
  // overflow: hidden;
}

.wide-img {
  @include div_l_pc(1920, 680, 0, 0);
  height: size(680);
  object-fit: cover;
  overflow: hidden;
  > div {
    transform-origin: center center;
    height: 1400px;
    top: calc(50% - 800px);
  }
  img {
    width: 100%;
    height: size(1080);
    top: 0%;
    transform: translateY(calc(-80% - 580px));
  }
}

.icon {
  @include img_l_pc(68, 680 + 89, 138);
}

.label {
  @include img_l_pc(227, 55 + 680, 242);
  font-size: size(20);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: size(2.8);
  text-align: left;
  color: #666666;
  white-space: nowrap;
}
.s-title {
  @include img_l_pc(649, 680 + 92, 241);
  font-size: size(38.1);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: size(1.9);
  text-align: left;
  color: #6e0000;
  white-space: nowrap;
}
.title {
  @include img_l_pc(454, 154 + 680, 241);
  font-size: size(55);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: size(2.75);
  text-align: left;
  color: #6e0000;
  white-space: nowrap;
}

.subtitle {
  @include img_l_pc(454, 554 + 680, 241);
  font-size: size(30.8);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: size(1.54);
  text-align: left;
  color: #000000;
  white-space: nowrap;
}

.s-desc {
  @include img_l_pc(454, 272 + 680, 241);
  padding-left: size(13);
  border-left: 1px solid #000000;
  font-size: size(19);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: size(0.95);
  text-align: left;
  white-space: nowrap;
  color: #000000;
}
.desc {
  @include img_l_pc(657, 454 + 680, 241);
  font-size: size(19);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: size(0.95);
  text-align: left;
  white-space: nowrap;
  color: #000000;
}

.arrow1 {
  @include img_r_pc(190, 0, 0);
  top: auto;
  bottom: size(181);
}

.arrow2 {
  @include img_r_pc(135, 0, 0);
  top: auto;
  bottom: size(128);
}

// begin
.fade-up-leave-to {
  transform: translateY(15px);
  opacity: 0;
  z-index: 0;
}
// end
.fade-up-enter {
  transform: translateY(55px);
  opacity: 0;
  z-index: 1;
}

.fade-up-enter-active {
  transition: all 0.5s ease;
}

.fade-up-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.border-frame {
  @include div_r_pc(660, 790, 610, 240);
  border: solid 1px #aa915e;

  &::after {
    content: '';
    @include div_l_pc(78, 78, 793 - 82, 0);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: size(78) 0 0 size(78);
    border-color: transparent transparent transparent #aa915e;
    z-index: 2;
  }
}

/* Swipe */
.swipe {
  width: size(660);
  height: size(790);
  top: size(580);
  right: size(209);
  object-fit: cover;
  // background: #0344;
}

// begin
.swipe-fade-leave-to {
  opacity: 0;
  z-index: 0;
}
// end
.swipe-fade-enter {
  opacity: 0;
  z-index: 1;
}

.swipe-fade-enter-active {
  transition: all 0.3s ease;
}

.swipe-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

// begin
// .swipe-left-leave-to {
//   margin-left: -100vw;
//   z-index: 0;
// }
// // end
// .swipe-left-enter {
//   opacity: 0.5;
//   margin-left: 0;
//   z-index: 1;
// }

// .swipe-left-enter-active {
//   transition: all 0.5s ease;
// }

// .swipe-left-leave-active {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }

.swipe-wrap {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}

.swipe-item {
  width: 100%;
  height: 100%;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slide-name {
    right: 1.2em;
    bottom: 0.5em;
    color: #fff;
    font-size: size(18);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.03em;
    text-align: left;
    text-shadow: 0 0.1em 0.5em #000;
  }

  // &:nth-child(1) {
  //   z-index: 1;
  //   // opacity: 1;
  // }

  // &.base {
  //   z-index: 1;
  //   opacity: 1;
  // }
  // &.active {
  //   z-index: 2;
  //   // opacity: 1;
  // }
}

.pagination {
  width: auto;
  bottom: size(25);
  // right: 0;
  left: size(33);
  // margin: 0 auto;
  justify-content: center;
}

.pagination-dot {
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  z-index: 4;

  span {
    display: block;
    width: size(20);
    height: size(20);
    border-radius: 0px;
    box-shadow: 0 0 0 1px #aa915e;
    position: relative;
    background-color: #aa915e;
    transition: all 0.5s;

    &::before {
      content: '';
      width: 60%;
      height: 60%;
      display: block;
      background: #aa915e;
      border-radius: 20px;
      opacity: 1;
      position: absolute;
      top: 20%;
      // transform: translateY(-50%);
      left: 20%;
      transition: all 0.3s;
      transform-origin: center;
      transform: scale(0);
    }
    &:hover,
    &.active {
      box-shadow: 0 0 0 1px #fff;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: #fff;
        border-radius: 0px;
        opacity: 1;
        position: absolute;
        top: 0%;
        // transform: translateY(-50%);
        left: 0%;
        transform: scale(1);
      }
    }
  }
}

.swipe-btns {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  z-index: 3;

  .prev-btn,
  .next-btn {
    width: size(20);
    cursor: pointer;
  }
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section5 {
    width: 100vw;
    height: sizem(751 + 182);
    min-height: auto;
    max-height: initial;
    // background-image: url('./all/section_bg.jpg');
    // background-attachment: scroll;
    // background-image: url('./s2/bg.jpg');
    // background-size: 100% 100%;
    // background-position: 0 0;
    // background-attachment: fixed;
    // overflow: hidden;
  }

  .wide-img {
    @include div_l_m(375, 182, 0, 0);
    height: sizem(182);
    object-fit: cover;
    overflow: hidden;
    > div {
      transform-origin: center center;
      height: 300px;
      top: calc(50% - 100px);
    }
    img {
      width: 100%;
      height: sizem(380);
      top: 0;
    transform: translateY(calc(-210% + 300px));
    }
  }

  .icon {
    @include img_l_m(46.6, 200, 30);
  }

  .label {
    @include img_l_m(200, 182 + 42, 81);
    font-size: sizem(13);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: sizem(1.82);
    text-align: left;
    color: #666666;
    white-space: nowrap;
  }
  .s-title {
    @include img_l_m(200, 182 + 85, 33);
    font-size: sizem(21);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: sizem(1.05);
    text-align: left;
    color: #6e0000;
    white-space: normal;
  }
  .title {
    @include img_l_m(200, 182 + 189, 33);
    font-size: sizem(33);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #6e0000;
    white-space: normal;
  }

  .subtitle {
    @include img_l_m(292, 182 + 612, 33);
    font-size: sizem(18);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: sizem(2.52);
    text-align: left;
    color: #530000;
    white-space: nowrap;
  }

  .s-desc {
    @include img_l_m(314, 182 + 297, 33);
    padding-left: sizem(12);
    border-left: 1px solid #000000;
    font-size: sizem(15);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: sizem(0.15);
    text-align: left;
    white-space: nowrap;
    color: #000000;
  }
  .desc {
    @include img_l_m(314, 182 + 528, 33);
    font-size: sizem(15);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: sizem(0.75);
    text-align: left;
    white-space: normal;
    color: #000000;
  }

  .arrow1 {
    @include img_r_m(190, 0, 0);
    top: auto;
    bottom: size(181);
    display: none;
  }

  .arrow2 {
    @include img_r_m(135, 0, 0);
    top: auto;
    bottom: size(128);
    display: none;
  }

  // begin
  .fade-up-leave-to {
    transform: translateY(15px);
    opacity: 0;
    z-index: 0;
  }
  // end
  .fade-up-enter {
    transform: translateY(55px);
    opacity: 0;
    z-index: 1;
  }

  .fade-up-enter-active {
    transition: all 0.5s ease;
  }

  .fade-up-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .border-frame {
    @include div_r_m(194, 234, 182 + 51, 0);
    border: solid 1px #aa915e;

    &::after {
      content: '';
      @include div_l_m(23, 23, 0, 0);
      top: auto;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: sizem(23) 0 0 sizem(23);
      border-color: transparent transparent transparent #aa915e;
      z-index: 2;
    }
  }

  /* Swipe */
  .swipe {
    width: sizem(185);
    height: sizem(233);
    top: sizem(182 + 43);
    right: sizem(0);
    object-fit: cover;
    // background: #0344;
  }

  // begin
  .swipe-fade-leave-to {
    opacity: 0;
    z-index: 0;
  }
  // end
  .swipe-fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .swipe-fade-enter-active {
    transition: all 0.5s ease;
  }

  .swipe-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  // begin
  // .swipe-left-leave-to {
  //   margin-left: -100vw;
  //   z-index: 0;
  // }
  // // end
  // .swipe-left-enter {
  //   opacity: 0.5;
  //   margin-left: 0;
  //   z-index: 1;
  // }

  // .swipe-left-enter-active {
  //   transition: all 0.5s ease;
  // }

  // .swipe-left-leave-active {
  //   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  // }

  .swipe-wrap {
    width: 100%;
    height: 100%;
    // overflow: hidden;
  }

  .swipe-item {
    width: 100%;
    height: 100%;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    // &:nth-child(1) {
    //   z-index: 1;
    //   // opacity: 1;
    // }

    // &.base {
    //   z-index: 1;
    //   opacity: 1;
    // }
    // &.active {
    //   z-index: 2;
    //   // opacity: 1;
    // }
    .slide-name {
      right: auto;
      top: auto;
      bottom: 1.2rem;
      right: 1.2rem;
      font-size: sizem(15);
    }
  }

  .pagination {
    width: auto;
    bottom: size(91);
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: center;
  }

  .pagination-dot {
    padding: 5px;
    margin: 0 10px;
    cursor: pointer;
    z-index: 4;

    span {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      box-shadow: 0 0 0 1px #fff;
      position: relative;
      background-color: rgba(0, 0, 0, 0.01);
      transition: all 0.5s;

      &::before {
        content: '';
        width: 60%;
        height: 60%;
        display: block;
        background: #004ea2;
        border-radius: 20px;
        opacity: 1;
        position: absolute;
        top: 20%;
        // transform: translateY(-50%);
        left: 20%;
        transition: all 0.3s;
        transform-origin: center;
        transform: scale(0);
      }
      &.active {
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          background: #004ea2;
          border-radius: 20px;
          opacity: 1;
          position: absolute;
          top: 0%;
          // transform: translateY(-50%);
          left: 0%;
          transform: scale(1);
        }
      }
    }
  }

  .swipe-btns {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    z-index: 3;

    .prev-btn,
    .next-btn {
      width: sizem(15);
      cursor: pointer;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import info from '@/info'
import slider from '@/mixins/slider.js'

export default {
  name: 'section5',

  mixins: [slider],
  props: ['viewIndex'],

  data() {
    return {
      info,
      isPC,
      isMobile,
      isTablet,
      isDialog: false,
      blockIndex: 0,
      slideIndex1: 0,
      slideList: [
        {
          img: require('./s5/1.jpg'),
        },
        {
          img: require('./s5/2.jpg'),
        },
        {
          img: require('./s5/3.jpg'),
        },
        {
          img: require('./s5/4.jpg'),
        },
      ],
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},

  watch: {
    viewIndex() {
      if (this.viewIndex === 5) {
        this.slideIndex = 0
        console.log(this.slideIndex, 'slideIndex')
      }
    },
  },
}
</script>
